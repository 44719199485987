import React from "react"
import { Box, Grid, Heading, Container, Flex, Text } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"

const SimpleImageGrid = ({ kicker, title, paragraph, viewMore, gallery }) => {
  return (
    <Box sx={{ mt: [-9], my: [4], mb: [8] }}>
      <Container sx={{p:[3,3,3,0]}}>
        <Flex
          sx={{
            flexDirection: ["column-reverse","column-reverse", "row-reverse"],
          }}
        >
          <Box
            sx={{
              pl: [3, 3,3, 7],
              pr: [3, 3, 3, 5],
              textAlign: "right",
              width: ["100%","100%","55%"],
              position: "relative",
              ".gatsby-image-wrapper": {
                width: ["100%", "100%", "100%", "90%"],
                maxHeight: ["550px", "550px"],
                position: "relative",
              },
            }}
          >
            <Grid columns={[1, 2]} gap={[16]} sx={{width: ["100%","100%","100%","120%"]}}>
              {gallery.map((image, index) => {
                return (
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      mt: [3, index === 1 || index === 3 ? 7 : null],
                      ".gatsby-image-wrapper": {
                        height: "100%",
                        width: "100%",
                      },
                    }}
                  >
                    <GatsbyImage image={image.gatsbyImageData} alt="" />
                  </Box>
                )
              })}
            </Grid>
          </Box>
          <Box
            sx={{
              width: ["100%","100%","45%"],
              display: "flex",
              alignContent: "center",
            }}
          >
            <Flex
              sx={{
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <Text variant="kicker" sx={{ mt: 0, pb: [3] }}>
                {kicker}
              </Text>
              <Heading variant="h2" sx={{ color: "text", mt: 0, width: "80%" }}>
                {title}
              </Heading>
              <Box sx={{ py: [3] }}>
                <Text
                  as="div"
                  variant="sectionTitle"
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default SimpleImageGrid
