import React, { useState } from "react"
import { Box, Grid, Heading, Container, Text, Flex } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { StructuredText } from "react-datocms"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { useThemeUI } from "theme-ui"
import { MagicLink } from "../../utils/magicLink"
import { Bold } from "react-feather"
import { ChevronRight } from "react-feather"
import { useResponsiveValue, useBreakpointIndex } from "@theme-ui/match-media"

const TitleImageGridList = ({
  kicker,
  title,
  paragraph,
  viewMore,
  gallery,
}) => {

  return (
    <Box sx={{ my: [6,6,7,7] }}>
      <Container variant="xl">
        <Box sx={{ px: [3,3,3,0]}}>
          <Box>
            <Text variant="kicker">{kicker}</Text>
          </Box>
          <Box>
            <Heading variant="h2" sx={{ color: "text", mt: 0 }}>
              {title}
            </Heading>
          </Box>
          <Flex sx={{ flexDirection: ["column","row"] ,justifyContent: "space-between", alignItems: ["start","center"], pb:[3,0] }}>
            <Box sx={{ width: ["100%","50%"] }}>
              <Text
                as="div"
                variant="sectionTitle"
                sx={{ py: [5] }}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            </Box>
            <Box>
              <MagicLink
                item={viewMore}
                variant="buttons.full.primaryArrow"
                customAnchor={
                  <>
                    <span>{viewMore.anchor}</span>
                    <span>
                      <ChevronRight />
                    </span>
                  </>
                }
              />
            </Box>
          </Flex>
        </Box>

        <Grid
          sx={{ "grid-auto-rows": "1fr" }}
          columns={[1, 1, "1fr 1fr 1fr"]}
          gap={0}
        >
          {gallery.map((image, index) => {
            return <Item image={image} index={index + 1} />
          })}
        </Grid>
      </Container>
    </Box>
  )
}

const Item = ({ active, image, index }) => {
  const context = useThemeUI()
  const { colors } = context.theme

  const [show, setShow] = useState(false)

  const breakpointIndex = useBreakpointIndex()

  return (
    <Box
      sx={{
        height: "100%",
        position: "relative",
        ".gatsby-image-wrapper": {
          height: "100%",
        },
      }}
    >
      <motion.div
        style={{
          zIndex: 2222,
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "flex-end",
        }}
        whileHover={{
          backgroundColor: colors.overlayPrimary,
        }}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {(show || breakpointIndex === 0) && (
          <motion.div
            style={{ padding: "32px" }}
            animate={{
              color: "white",
              opacity: 1,
            }}
            initial={{ opacity: 0 }}
          >
            <Box sx={{ width: ["50%"] }}>
              <Text
                sx={{
                  fontSize: [5],
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                0{index}.
                <br />
                {image.title}
              </Text>
            </Box>
          </motion.div>
        )}
      </motion.div>

      <GatsbyImage image={image.gatsbyImageData} alt="" />
    </Box>
  )
}

export default TitleImageGridList
