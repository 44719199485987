import React from "react"
import { Container, Box, Flex, Grid, Heading, Text } from "@theme-ui/components"
import { MagicLink } from "../../utils/magicLink"
import { GatsbyImage } from "gatsby-plugin-image"
import { ChevronRight } from "react-feather"

const ContactFooterSection = ({ image, kicker, title, link }) => {
  return (
    <Box sx={{ position: "relative", minHeight: "600px", height: "600px", mt:[6] }}>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: -1,
          ".gatsby-image-wrapper": {
            width: "100%",
            height: "100%",
            zIndex: -1,
          },
        }}
      >
        <GatsbyImage image={image.gatsbyImageData} alt="" />
      </Box>
      <Container variant="xl" sx={{height: "100%", display: "flex", flexDirection: "column" , alignContent: "center", alignItems:"center" , justifyContent:"center"}}>
          <Box>
              <Text variant="kicker" sx={{color: "light"}}>
                {kicker}
              </Text>
          </Box>
          <Box>
              <Heading  variant="h2" sx={{color: "light" , maxWidth: "460px", textAlign:"center"}} >
                {title}
              </Heading>
          </Box>
          <Box sx={{ py: [6] }}>
            <MagicLink
              item={link}
              variant="buttons.full.primaryArrow"
              sx={{color: "light"}}
              customAnchor={
                <>
                  <span>{link.anchor}</span>
                  <span>
                    <ChevronRight />
                  </span>
                </>
              }
            />
          </Box>
      </Container>
    </Box>
  )
}

export default ContactFooterSection
