import React from "react"
import { Box, Flex, Grid, Heading, Text, Container, Image } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import { ChevronRight } from "react-feather"

// Begin swiper
import SwiperCore, { Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css/bundle"
import RichContentStructuredText from "../richContentStructuredText"
import { MagicLink } from "../../utils/magicLink"

SwiperCore.use([Mousewheel, Pagination, A11y])
// End swiper

const ItemCarousel = ({ items }) => {
  return (
    <Box sx={{ my: 7 }}>
      <Container variant="xl"> 
      <Grid sx={{
         gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))"
      }} gap={[4]}>
        {
        items.map((item)=>{
          return <Item richContent={item} />
        })
        }
      </Grid>
      </Container>
    </Box>
  )
}

const Item = ({ richContent }) => (
  <Flex sx={{ flexDirection: "column", alignItems: "start",  px: [5],py:[3] , backgroundColor :"primary" , "& > *" :{color: "light"} }}>
    <Box
    sx={{
      height: "100px",
      display:"flex",
      alignItems:"center",
      ".gatsby-image-wrapper": {
        
      }
    }}>
        {richContent.image.gatsbyImageData ?
          <GatsbyImage image={richContent.image.gatsbyImageData} alt="" />
          :
          <Image sx={{}} src={richContent.image.url} />
        }
        
    </Box>
    <Heading variant="h4" sx={{ color: "light", mt: 0 }}>
      {richContent.title}
    </Heading>
    <Box sx={{  "p" : {pt:[1],pb:[2], color: "light"} }}>
      <RichContentStructuredText text={richContent.body} />
    </Box>
  </Flex>
)

export default ItemCarousel
