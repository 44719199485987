import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"

const BannerSection = ({ image, kicker,title, description }) => {
  return (
    <Box sx={{ py: [4], position: "relative" }}>
      <Box
        sx={{
          maxWidth: "1920px",
          width: "100%",
          height: "1005",
          backgroundColor: "primary",
        }}
      >
        <Grid columns={[1, 1, 1, "7fr 3fr 2fr"]} gap={[0]}>
          <Box
            sx={{
              ".gatsby-image-wrapper": {
                height: "100%",
                width:"100%",
                img:{
                    maxHeight:["600px"],
                    objectFit:"cover!important"
                }
              },
            }}
          >
            <GatsbyImage image={image.gatsbyImageData} alt="" />
          </Box>
          <Box sx={{ backgroundColor: "lightGrey", p: [3, 5, 5], display:"flex", flexDirection:"column", justifyContent:"center" }}>
            <Box >
              <Text variant="kicker" sx={{ fontSize: [2] }}>
                {kicker}
              </Text>
            </Box>
            <Box>
              <Heading
                variant="h3"
                as="h2"
                sx={{ color: "text" }}
              >
                {title}
              </Heading>
            </Box>
            <Box>
              <Text
                as="div"
                sx={{ my: [4] }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Box>
          </Box>
          <Box></Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default BannerSection
