import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import { ChevronRight } from "react-feather"

// Begin swiper
import SwiperCore, { Navigation, Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css/bundle"
import RichContentStructuredText from "../richContentStructuredText"
import { MagicLink } from "../../utils/magicLink"

SwiperCore.use([Navigation, Mousewheel, Pagination, A11y])
// End swiper

const ItemCarousel = ({ items }) => {
  return (
    <Box sx={{ my: [5, 7] }}>
      {items.length > 1 ? (
        <Container variant="xl">
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            autoHeight={false}
            className="itemCarousel"
            pagination={{
              clickable: true,
            }}
            onSwiper={swiper => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            {items.map(item => (
              <SwiperSlide key={item.originalId}>
                <Item itemLength={items.length} richContent={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      ) : (
        <Flex sx={{ flexDirection: ["column", "column", "row", "row"] }}>
          <Box
            sx={{
              flex: "0 1 100%",
              maxWidth: [
                "calc(100% - -0.001px)",
                "calc(100% - -0.001px)",
                "calc(50% - -0.001px)",
                "calc(50% - -0.001px)",
              ],
              textAlign: "right",
              position: "relative",
              ".gatsby-image-wrapper": {
                width: ["100%", "100%", "100%", "90%"],
                maxHeight: ["550px", "550px"],
                position: "relative",
                "&:after": {
                  content: "''",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "15%",
                  backgroundColor: "overlayPrimary",
                  zIndex: 2,
                },
              },
            }}
          >
            <GatsbyImage image={items[0].image.gatsbyImageData} alt="" />
          </Box>
          <Box
            sx={{
              pt: [4, 4, 0, 0],
              pl: [3, 3, 5, 7],
              pr: [3, 3, 5, 5],
              flex: [
                "0 0 100%",
                "0 0 100%",
                "0 0 600.001px",
                "0 0 600.001px",
              ],
              display: "flex",
              alignContent: "center",
            }}
          >
            <Item richContent={items[0]} />
          </Box>
        </Flex>
      )}
    </Box>
  )
}

const Item = ({ richContent, itemLength }) => (
  <Grid
    sx={{
      alignItems: "start",
      mb: [3, 6, 6],
      height:
        "calc(100% - var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,8px)) * 8)",
    }}
    columns={[1, itemLength > 1 ? 2 : 1, itemLength > 1 ? 2 : 1]}
  >
    {itemLength > 1 && (
      <Box
        sx={{
          height: "100%",
          ".gatsby-image-wrapper": {
            height: "100%",
            "&:after": {
              content: "''",
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "15%",
              backgroundColor: "overlayPrimary",
              zIndex: 2,
            },
          },
        }}
      >
        <GatsbyImage image={richContent.image.gatsbyImageData} alt="" />
      </Box>
    )}
    <Box sx={{ p: [0, itemLength > 1 && 6], minHeight: "400px" }}>
      <Text variant="kicker" sx={{ mt: 0, pb: [3] }}>
        {richContent.label}
      </Text>
      <Heading variant="h2" sx={{ color: "text", mt: 0 }}>
        {richContent.title}
      </Heading>
      <Box sx={{ py: [3] }}>
        <RichContentStructuredText text={richContent.body} />
      </Box>
      {richContent.viewMore && (
        <Box sx={{ py: [3] }}>
          <MagicLink
            item={richContent.viewMore}
            variant="buttons.full.primaryArrow"
            customAnchor={
              <>
                <span>{richContent.viewMore.anchor}</span>
                <span>
                  <ChevronRight />
                </span>
              </>
            }
          />
        </Box>
      )}
    </Box>
  </Grid>
)

export default ItemCarousel
