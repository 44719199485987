import React from "react"
import { Box, Grid, Heading, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"

const SimpleImageGrid = ({ gallery }) => {
  return (
    <Box sx={{    mt: [-9] , py:[6]}}>
      <Container variant="xl">
        <Grid columns={[1, 1, "1fr 1fr 1fr"]} gap={3}>
          {gallery.map(image => {
            return (
              <Box
                sx={{
                  maxHeight: "250px",
                  width: "100%",
                  ".gatsby-image-wrapper": {
                    width :"100%",
                    height: "100%",
                  },
                }}
              >
                <GatsbyImage image={image.gatsbyImageData} alt="" />
              </Box>
            )
          })}
        </Grid>
      </Container>
    </Box>
  )
}

export default SimpleImageGrid
