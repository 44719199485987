import React from "react"
import { graphql } from "gatsby"
import { Box, Container, Heading } from "@theme-ui/components"
import Layout from "../components/layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import LatestArticles from "../components/latestArticles"
import { useFavicon } from "../hooks/useFavicon"
import PageHero from "./pageHero"
import SimpleImageGrid from "../components/blocks/simpleImageGrid"
import TitleImageGridList from "../components/blocks/titleImageGridList"
import ItemCarousel from "../components/blocks/itemCarousel"
import IconAndText from "../components/blocks/iconAndText"
import TitleTextImageGrid from "../components/blocks/titleTextImageGrid"
import ContactFooterSection from "../components/blocks/contactFooterSection"
import ImageAndText from "../components/blocks/imageAndText"
import BannerSection from "../components/blocks/bannerSection"
import { GatsbyImage } from "gatsby-plugin-image"

const Home = ({ data: { page, site, articles, contactFooter } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })
  console.log(page.content)

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} image={page.heroImage} home={true} />
      {page.content.map(block => (
        <Box as="section" key={block.id}>
          {block.model.apiKey === "simple_image_grid" && (
            <SimpleImageGrid gallery={block.gallery} />
          )}
          {block.model.apiKey === "item_carousel" && (
            <ItemCarousel items={block.items} />
          )}
          {block.model.apiKey === "title_image_grid_list" && (
            <TitleImageGridList
              kicker={block.kicker}
              title={block.title}
              paragraph={block.paragraph}
              viewMore={block.viewMore}
              gallery={block.gallery}
            />
          )}
          {block.model.apiKey === "text_with_right_image_grid" && (
            <TitleTextImageGrid
              kicker={block.kicker}
              title={block.title}
              paragraph={block.paragraph}
              viewMore={block.viewMore}
              gallery={block.gallery}
            />
          )}
          {block.model.apiKey === "full_width_image" && (
            <Container sx={{ my: [4,6]}}>
              <Box
                sx={{
                  
                  height: "100%",
                  width: "100%",
                  ".gatsby-image-wrapper": {
                    height: "100%",
                    width: "100%",
                  },
                }}
              >
                <GatsbyImage image={block.image.gatsbyImageData} alt="" />
              </Box>
            </Container>
          )}
          {block.model.apiKey === "banner_section" && (
            <BannerSection
              kicker={block.kicker}
              image={block.image}
              title={block.title}
              description={block.description}
            />
          )}
          {block.model.apiKey === "icon_and_text" && (
            <IconAndText items={block.content} />
          )}
          {block.model.apiKey === "image_and_text" && (
            <ImageAndText
              kicker={block.textContent.label}
              title={block.textContent.title}
              richContent={block.textContent}
              image={block.textContent.image}
              rightAligned={block.rightAlignment}
              fullWidth={block.fullWidth}
              imageMargin={block.imageMargin}
            />
          )}
        </Box>
      ))}
      <ContactFooterSection
        kicker={contactFooter.kicker}
        image={contactFooter.background}
        title={contactFooter.title}
        link={contactFooter.contactPageLink}
      />
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!, $locale: String!) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      locale
      body {
        value
      }
      heroImage {
        url
        gatsbyImageData(
          width: 1920
          placeholder: NONE
          forceBlurhash: false
          imgixParams: {
            blendColor: "#212C30"
            blendMode: "multiply"
            blendAlpha: 60
          }
        )
      }
      viewMore {
        ... on DatoCmsInternalLink {
          id
          anchor
          locale
          model {
            apiKey
          }
          link {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsBannerSection {
          image {
            gatsbyImageData(
              width: 1920
              placeholder: NONE
              forceBlurhash: false
            )
          }
          kicker
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsSimpleImageGrid {
          id
          gallery {
            gatsbyImageData(
              width: 1920
              placeholder: NONE
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsFullWidthImage {
          title
          image {
            gatsbyImageData(
              width: 1920
              placeholder: NONE
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleImageGridList {
          id
          kicker
          title
          paragraph
          viewMore {
            ... on DatoCmsInternalLink {
              id
              anchor
              locale
              model {
                apiKey
              }
              link {
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
              }
            }
          }
          gallery {
            title
            gatsbyImageData(
              width: 1920
              placeholder: NONE
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTextWithRightImageGrid {
          id
          kicker
          title
          paragraph
          viewMore {
            ... on DatoCmsInternalLink {
              id
              anchor
              locale
              model {
                apiKey
              }
              link {
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
              }
            }
          }
          gallery {
            title
            gatsbyImageData(
              width: 1920
              placeholder: NONE
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndText {
          id
          imageMargin
          rightAlignment
          textContent: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsIconAndText {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
      }
    }
    contactFooter: datoCmsContactFooterSection(locale: { eq: $locale }) {
      id
      kicker
      title
      model {
        apiKey
      }
      background {
        gatsbyImageData(
          width: 1920
          placeholder: NONE
          forceBlurhash: false
          imgixParams: {
            blendColor: "#212C30"
            blendMode: "multiply"
            blendAlpha: 70
          }
        )
      }
      contactPageLink {
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale

          model {
            apiKey
          }
          link {
            ... on DatoCmsBlogPage {
              ...BlogDetails
            }
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
            ... on DatoCmsArticle {
              ...ArticleDetails
              ...ArticleAllSlugLocales
            }
            ... on DatoCmsArticleCategory {
              ...ArticleCategoryDetails
              ...ArticleCategoryAllSlugLocales
            }
          }
        }
      }
    }

    articles: allDatoCmsArticle(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      limit: 6
    ) {
      nodes {
        ...ArticleDetails
        ...ArticleAllSlugLocales
        ...ArticleMeta
      }
    }
    site: datoCmsSite {
      locales
    }
  }
`
