import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import { MagicLink } from "../../utils/magicLink"
import { ChevronRight } from "react-feather"
import CheckCircle from "../../images/checkcirle.svg"

const ImageAndText = ({
  kicker,
  title,
  richContent,
  image,
  rightAligned,
  fullWidth,
  imageMargin,
  noTitle = false,
}) => {
  return (
    <Box sx={{ py: 6, mt: [5,5,8], position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          height: "calc(100% - 96px)",
          width: "100%",
          py: [9],
          marginRight: "auto",
          zIndex: "-1",
          background: !rightAligned
            ? "linear-gradient(90deg, #f3f3f3 50%, rgba(0,0,0,0) 50% )"
            : "linear-gradient(90deg, rgba(0,0,0,0) 50%, #f3f3f3 50% )",
        }}
      ></Box>
      <Box
        sx={{
          position: ["relative","relative","absolute"],
          "-webkit-flex": "0 1 100%",
          "-ms-flex": "0 1 100%",
          flex: "0 1 100%",
          top: 0,
          right: rightAligned && 0,
          maxWidth: ["calc(100% - -0.001px)","calc(100% - -0.001px)","calc(50% - -0.001px)"],
          textAlign: "right",
          height: "100%",
          ".gatsby-image-wrapper": {
            width: ["100%", "100%", "100%", "100%"],
            height: "calc( 100% - 96px)",
            position: "relative",
            "&:after": {
              content: "''",
              position: "absolute",
              bottom: 0,
              left: !rightAligned && 0,
              right: rightAligned && 0,
              width: imageMargin ? "25%" : "100%",
              height: imageMargin ? "100%" : "15%",
              backgroundColor: "overlayPrimary",
              zIndex: 2,
            },
          },
        }}
      >
        <GatsbyImage image={image.gatsbyImageData} alt="" />
      </Box>
      <Container variant="xl">
        <Flex
          sx={{
            backgroundColor: "lightGrey",
            py: [6],
            flexDirection: !rightAligned && "row-reverse",
            minHeight: ["auto","500px"],
          }}
        >
          <Box
            sx={{
              pl: [3,3,7],
              pr: [3,3,5],
              flex: ["0 0 100%","0 0 100%","0 0 600.001px"],
              maxWidth: ["100%","100%","600.001px"],
              display: "flex",
              alignContent: "center",
            }}
          >
            <Item noTitle={noTitle} richContent={richContent} imageMargin={imageMargin} />
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

const Item = ({ richContent, imageMargin, noTitle }) => (
  <Flex
    sx={{
      flexDirection: "column",
      alignItems: "start",
      justifyContent: imageMargin && "center",
      px: [0,0,5],
      py: [0,0,2],
    }}
  >
    <Text variant="kicker" sx={{ mt: 0, pb: [3] }}>
      {richContent.label}
    </Text>
    {!noTitle && (
      <Heading
        variant={imageMargin ? "h3" : "h2"}
        sx={{ color: "text", mt: 0 }}
      >
        {richContent.title}
      </Heading>
    )}
    <Box
      sx={{
        "* ul": {
          columnCount: 2,
          li: {
            "&:before": {
              content: "''",
              display: "inline-block",
              height: "20px",
              width: "20px",
              backgroundImage: "url(" + CheckCircle + ")",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              mr: "0.5em",
              top: 2
            },
            pt: [2],
          },
        },
      }}
    >
      <RichContentStructuredText text={richContent.body} />
    </Box>

    {richContent.viewMore && (
      <Box sx={{ py: [3] }}>
        <MagicLink
          item={richContent.viewMore}
          variant="buttons.full.primaryArrow"
          customAnchor={
            <>
              <span>{richContent.viewMore.anchor}</span>
              <span>
                <ChevronRight />
              </span>
            </>
          }
        />
      </Box>
    )}
  </Flex>
)

export default ImageAndText
